import enLocale from './en.yml'
import enUpdatesLocale from './enUpdates.yml'
import ruLocale from './ru.yml'
import ruUpdatesLocale from './ruUpdates.yml'
import ukLocale from './uk.yml'
import ukUpdatesLocale from './ukUpdates.yml'
import zhLocale from './zh.yml'
import zhUpdatesLocale from './zhUpdates.yml'
import merge from 'lodash.merge'

export const interpolate = (s, params) => {
  return s.replace(/%\{(.*?)\}/g, (expression, argument) => {
    return params[argument]
  })
}

export const locales = {
  en_US: merge(enLocale.en, enUpdatesLocale.en),
  ru_RU: merge(ruLocale.ru, ruUpdatesLocale.ru),
  uk_UA: merge(ukLocale.uk, ukUpdatesLocale.uk),
  zh_TW: merge(zhLocale.zh, zhUpdatesLocale.zh)
}
