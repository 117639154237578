import React, { useContext, useState } from 'react'

const AdminContext = React.createContext(null)

export const useAdmin = () => {
  return useContext(AdminContext)
}

export const AdminContextConsumer = AdminContext.Consumer

export const VIEW_AS_USER = {
  id: null
}

const AdminProvider = ({ children }) => {
  const [currentAdmin, setCurrentAdmin] = useState(null)
  const [viewAsUser, setViewAsUser] = useState(null)
  const state = {
    currentAdmin,
    setCurrentAdmin,
    viewAsUser,
    setViewAsUser: (user) => {
      setViewAsUser(user)
      VIEW_AS_USER.id = user ? user.id : null
    }
  }
  return <AdminContext.Provider value={state}>{children}</AdminContext.Provider>
}

export default AdminProvider
