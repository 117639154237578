import React, { useContext, useState } from 'react'
import { locales, interpolate } from '../../locale/LocalizationUtils'

const LocalizationContext = React.createContext(null)

export const useLocalization = () => {
  return useContext(LocalizationContext)
}

export const LocalizationContextConsumer = LocalizationContext.Consumer

const LocalizationProvider = ({ children }) => {
  const [localeKey, setLocaleKey] = useState(window.locale || 'en_US')
  const state = {
    localeKey: localeKey,
    locale: locales[localeKey],
    interpolate,
    setLocaleKey
  }
  return (
    <LocalizationContext.Provider value={state}>
      {children}
    </LocalizationContext.Provider>
  )
}

export default LocalizationProvider
